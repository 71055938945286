import { ApiDataSource } from "../../apollo/apiDataSource";
import loadingModule from "./loading.module.js";

export default {
    state() {
        return {
            users: [],
            user: null
        }
    },
    namespaced: true,
    mutations: {
        setUsers(state, users) {
            state.users = users;
        },
        setUser(state, user) {
            state.user = user;
        },
        removeUser(state, id) {
            state.users = state.users.filter(u => u.id != id);
        }
    },
    actions: {
        async fetchAll(context) {
            await ApiDataSource.fetchAccessTokenIfExpired();
            context.dispatch("loading/start");
            const users = await ApiDataSource.getAllUsers();
            context.commit("setUsers", users);
            context.dispatch("loading/stop");
        },
        async fetchUser(context, id) {
            await ApiDataSource.fetchAccessTokenIfExpired();
            context.dispatch("userLoading/start");
            const user = await ApiDataSource.getUser(id);
            context.commit("setUser", user);
            context.dispatch("userLoading/stop");
        },
        async updateUser(context, args) {
            await ApiDataSource.fetchAccessTokenIfExpired();
            await ApiDataSource.updateUser(
                args.id,
                args.username,
                args.email,
                args.password,
                args.isAdmin,
                args.isOperator,
                args.isMaintenance
            );
        },
        async createUser(context, args) {
            await ApiDataSource.fetchAccessTokenIfExpired();
            await ApiDataSource.createUser(
                args.username,
                args.email,
                args.password,
                args.isAdmin,
                args.isOperator,
                args.isMaintenance
            );
        },
        async deleteUser(context, id) {
            await ApiDataSource.fetchAccessTokenIfExpired();
            await context.commit("removeUser", id);
            await ApiDataSource.deleteUser(id);
        }
    },
    modules: {
        loading: loadingModule,
        userLoading: loadingModule
    }
}

import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'

// HTTP connection to the API
const httpLink = createHttpLink({
    // You should use an absolute URL here
	uri: process.env.NODE_ENV == "production" ? "https://aerolox.ch/graphql" : "https://aerolox.ch/graphql",
});

// Cache implementation
const cache = new InMemoryCache({
    resultCaching: false,
});

// Create the apollo client
export const apolloClient = new ApolloClient({
    link: httpLink,
    cache,
    defaultOptions: {
        query: {
            fetchPolicy: 'no-cache'
        }
    }
});

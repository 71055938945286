<template>
    <header
        v-click-outside="closeMenu"
        class="fixed top-0 left-0 right-1/4 bottom-0 z-10 md:relative pr-3 hidden lg:flex transition-all shadow-lg rounded-r-lg h-full flex-col bg-white dark:bg-gray-700"
        :class="{open: $store.state.menu.open}"
    >
        <router-link to="/" class="m-8 flex items-center gap-4">
            <div class="w-14 h-14 rounded-full overflow-hidden">
                <img src="../assets/logo.png" alt="">
            </div>
            <h1 class="text-lg">AEROLOX</h1>
        </router-link>
        <nav class="flex-1">
            <HeaderNavItem
                :selected="mapSelected"
                :icon="icons.mdiMap"
                title="Carte"
                link="/map"
            />
            <HeaderNavItem
                :selected="groupsSelected"
                :icon="icons.mdiViewListOutline"
                title="Zones"
                link="/groups"
            />
        </nav>
        <nav>
            <HeaderNavItem
                v-if="$store.state.parsedAccessToken.isAdmin"
                :selected="usersSelected"
                :icon="icons.mdiAccountGroup"
                title="Utilisateurs"
                link="/users"
            />
            <HeaderNavItem
                :selected="accountSelected"
                :icon="icons.mdiAccount"
                title="Mon Compte"
                link="/account"
            />
            <HeaderNavItem
                :selected="false"
                :icon="icons.mdiLogout"
                title="Se déconnecter"
                @click="logout"
            />
        </nav>
    </header>
</template>

<script>
import HeaderNavItem from "./HeaderNavItem.vue";
import * as icons from "@mdi/js"

export default {
    name: "Header",
    components: {HeaderNavItem},
    data() {
        return {
            icons
        }
    },
    methods: {
        logout() {
            this.$store.dispatch("logout");
        },
        closeMenu() {
            if(this.$store.state.menu.open)
            {
                this.$store.dispatch("menu/close");
            }
        }
    },
    computed: {
        usersSelected() {
            return this.$route.name == "Users";
        },
        accountSelected() {
            return this.$route.name == "Account";
        },
        groupsSelected() {
            return ["Groups", "Group", "Station", "Commands"].includes(this.$route.name);
        },
        mapSelected() {
            return this.$route.name == "Map";
        }
    }
}
</script>

<style scoped>
header {
    z-index: 99999999 !important;
}
header.open {
    @apply flex;
}
</style>

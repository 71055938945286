import { apolloClient } from "./client.js";
import {store} from "../store/store.js";
import qgl from "graphql-tag";

export class ApiDataSource {
    static getContext()
    {
        return {
            headers: {
                Authorization: store.state.accessToken
            }
        }
    }

    static async fetchAccessTokenIfExpired()
    {
        // Refresh accessToken if token expires in less than 60s
        if(store.state.parsedAccessToken.exp < Date.now() / 1000 + 60)
        {
            try
            {
                await store.dispatch("fetchAccessToken");
            }
            catch(err)
            {
                await store.dispatch("logout");
                throw err;
            }
        }
    }

    static async createRefreshToken(email, password) {
        const mutation = qgl`
            mutation authenticate($email: String!, $password: String!) {
                authenticate(email: $email, password: $password) {
                    refreshToken
                }
            }
        `;
        return (await apolloClient.mutate({
            mutation,
            context: ApiDataSource.getContext(),
            variables: {email, password}
        })).data.authenticate;
    }

    static async createAccessToken(refreshToken) {
        const mutation = qgl`
            mutation accessToken($refreshToken: String!) {
                accessToken(refreshToken: $refreshToken) {
                    refreshToken,
                    accessToken
                }
            }
        `;
        return (await apolloClient.mutate({
            mutation,
            context: ApiDataSource.getContext(),
            variables: {refreshToken}
        })).data.accessToken;
    }

    static async getAllUsers() {
        const query = qgl `
            query {
                users {
                    id
                    username
                    email
                    isAdmin
                    isOperator
                    isMaintenance
                }
            }
        `;
        return (await apolloClient.query({
            query,
            context: ApiDataSource.getContext()
        })).data.users;
    }
    static async getMyUser() {
        const query = qgl `
            query {
                me {
                    id
                    username
                    email
                    isAdmin
                    isOperator
                    isMaintenance
                }
            }
        `;
        return (await apolloClient.query({
            query,
            context: ApiDataSource.getContext()
        })).data.me;
    }

    static async getUser(id) {
        const query = qgl `
            query user($id: String!) {
                user(id: $id) {
                    id
                    username
                    email
                    isAdmin
                    isOperator
                    isMaintenance
                }
            }
        `;
        return (await apolloClient.query({
            query,
            context: ApiDataSource.getContext(),
            variables: {id}
        })).data.user;
    }

    static async updateUser(id, username, email, password, isAdmin, isOperator, isMaintenance) {
        const query = qgl `
            mutation updateUser($id: String!, $username: String, $email: String, $password: String, $isAdmin: Boolean, $isOperator: Boolean, $isMaintenance: Boolean) {
                updateUser(id: $id, username: $username, email: $email, password: $password, isAdmin: $isAdmin, isOperator: $isOperator, isMaintenance: $isMaintenance) {
                    id
                    username
                    email
                    isAdmin
                    isOperator
                    isMaintenance
                }
            }
        `;
        return (await apolloClient.query({
            query,
            context: ApiDataSource.getContext(),
            variables: {
                id, username, email, password, isAdmin, isOperator, isMaintenance
            }
        })).data.updateUser;
    }

    static async createUser(username, email, password, isAdmin, isOperator, isMaintenance) {
        const query = qgl `
            mutation createUser($username: String!, $email: String!, $password: String!, $isAdmin: Boolean, $isOperator: Boolean, $isMaintenance: Boolean) {
                createUser(username: $username, email: $email, password: $password, isAdmin: $isAdmin, isOperator: $isOperator, isMaintenance: $isMaintenance) {
                    username
                    email
                    isAdmin
                    isOperator
                    isMaintenance
                }
            }
        `;
        return (await apolloClient.query({
            query,
            context: ApiDataSource.getContext(),
            variables: {
                username, email, password, isAdmin, isOperator, isMaintenance
            }
        })).data.createUser;
    }

    static async deleteUser(id) {
        const query = qgl `
            mutation deleteUser($id: String!) {
                deleteUser(id: $id)
            }
        `;
        return (await apolloClient.query({
            query,
            context: ApiDataSource.getContext(),
            variables: {
                id
            }
        })).data.deleteUser;
    }

    static async getGroups() {
        const query = qgl `
            query {
                groups {
                    id
                    name
                    stations {
                        id
                    }
                }
            }
        `;
        return (await apolloClient.query({
            query,
            context: ApiDataSource.getContext()
        })).data.groups;
    }

    static async getGroup(groupId) {
        const query = qgl`
            query group($groupId: String!) {
                group(id: $groupId) {
                    id
                    name
                    stations {
                        id
                        mqttTopic
                        name
                        description
                        lastUpdate
                    }
                    polygon
                }
            }
        `;
        return (await apolloClient.query({
            query,
            context: ApiDataSource.getContext(),
            variables: { groupId }
        })).data.group;
    }

    static async getGroupWithInstallations(groupId) {
        const query = qgl`
            query group($groupId: String!) {
                group(id: $groupId) {
                    id
                    name
                    stations {
                        id
                        mqttTopic
                        lastUpdate
                        name
                        description
                        gpslat
                        gpslong
                        equipmentInstallations {
                            mqttTopic
                            equipment {
                                id
                                icon
                                name
                                description
                                equipmentIdentifier
                            }
                        }
                    }
                }
            }
        `;
        return (await apolloClient.query({
            query,
            context: ApiDataSource.getContext(),
            variables: { groupId }
        })).data.group;
    }

    static async getStation(stationId) {
        const query = qgl`
            query station($stationId: String!) {
                station(id: $stationId) {
                    id
                    name
                    stationGroupId
                    lastUpdate
                    mqttTopic
                    description
                    gpslat
                    gpslong
                    equipmentInstallations {
                        mqttTopic
                        sensorData {
                            type
                            value
                            battery
                            rssi
                        }
                        equipment {
                            id
                            name
                            icon
                            description
                            equipmentIdentifier
                            equipmentDatafields {
                                type
                                label
                                icon
                                unit
                            }
                        }
                    }
                }
            }
        `;
        return (await apolloClient.query({
            query,
            context: ApiDataSource.getContext(),
            variables: { stationId }
        })).data.station;
    }

    static async getRunningCommands(stationId) {
        const query = qgl`
            query station($stationId: String!) {
                runningCommands(stationId: $stationId) {
                    id
                    command
                    value
                    topic
                    startDate
                    endDate
                }
            }
        `;
        return (await apolloClient.query({
            query,
            context: ApiDataSource.getContext(),
            variables: { stationId }
        })).data.runningCommands;
    }

    static async getActiveEvents(stationId) {
        const query = qgl`
            query activeEvents($stationId: String!) {
                activeEvents(stationId: $stationId) {
                    id
                    stationId
                    topic
                    value
                    category
                    eventDate
                    seen
                    eventMessage {
                        message
                    }
                }
            }
        `;
        return (await apolloClient.query({
            query,
            context: ApiDataSource.getContext(),
            variables: { stationId }
        })).data.activeEvents;
    }

    static async getAvailableCommands() {
        const query = qgl`
            query {
                availableCommands {
                    title
                    command
                    equipmentIdentifier
                    availableCommandOptions {
                        title
                        value
                    }
                }
            }
        `;
        return (await apolloClient.query({
            query,
            context: ApiDataSource.getContext()
        })).data.availableCommands;
    }

    static async getWeatherWarnings() {
        const query = qgl`
            query {
                weatherWarnings {
                    id
                    emitDate
                    endDate
                    group
                    success
                    title
                    message
                    availableCommandOption {
                        title
                        availableCommand {
                            title
                        }
                    }
                }
            }
        `;
        return (await apolloClient.query({
            query,
            context: ApiDataSource.getContext()
        })).data.weatherWarnings;
    }

    static async getEvents(stationId, page) {
        const query = qgl`
            query events($stationId: String!, $page: Int!){
                events(stationId: $stationId, page: $page) {
                    count
                    pageSize
                    events {
                        id
                        stationId
                        topic
                        value
                        category
                        eventDate
                        seen
                        eventMessage {
                            message
                        }
                    }
                }
            }
        `;
        return (await apolloClient.query({
            query,
            context: ApiDataSource.getContext(),
            variables: {stationId, page}
        })).data.events;
    }

    static async setEventSeen(eventId, seen) {
        const query = qgl`
            mutation setEventSeen($eventId: String!, $seen: Boolean!) {
                setEventSeen(eventId: $eventId, seen: $seen) {
                    id
                    seen
                }
            }
        `;
        return (await apolloClient.query({
            query,
            context: ApiDataSource.getContext(),
            variables: {eventId, seen}
        })).data.setEventSeen;
    }

    static async sendCommand(stationId, command, topic, value, duration) {
        const mutation = qgl`
            mutation sendCommand($stationId: String!, $command: Int!, $value: Float, $topic: String, $duration: Int) {
                sendCommand(stationId: $stationId, command: $command, topic: $topic, value: $value, duration: $duration) {
                    success
                }
            }
        `;
        return (await apolloClient.mutate({
            mutation,
            context: ApiDataSource.getContext(),
            variables: {stationId, command, topic, value, duration}
        })).data.sendCommand;
    }
}

<template>
    <div class="shadow-md bg-white dark:bg-gray-800 rounded-md p-6">
        <h2 class="text-2xl mb-6">Nouvelle commande</h2>    
        <div class="flex items-center gap-4 mb-2">
            <div>Commande : </div>
            <Dropdown
                @update="resetFields"
                :modelValue="selectedCommand"
                defaultMessage="Select command"
                :options="availableCommands"
            />
        </div>
            <div v-if="selectedCommand" class="flex items-center gap-4 my-4">
                <div>Valeur : </div>
                <Dropdown
                    v-if="availableValues.length"
                    v-model="selectedValue"
                    defaultMessage="Select value"
                    :options="availableValues"
                />
            </div>
            <div v-if="selectedValue && selectedCommand.command" class="flex items-center gap-4">
                <input v-model="duration" type="checkbox">
                <div>Durée :</div>
                <div v-if="duration">
                    <input @change="checkDuration" v-model="inputDurationH" type="number" min="0" max="2">
                    <span class="mr-2">H</span>
                    <input @change="checkDuration" v-model="inputDurationM" type="number" min="0" max="59">
                    <span class="mr-2">M</span>
                    <input @change="checkDuration" v-model="inputDurationS" type="number" min="0" max="59">
                    <span class="mr-2">S</span>
                </div>
            </div>
        <div
            v-if="selectedCommand"
            class="flex flex-wrap items-center mb-4 mt-8 gap-2"
        >
            <h3 class="text-xl">Stations / Equipements</h3>
            <button
                @click="selectAll()"
                class="rounded-full bg-white dark:bg-gray-700 shadow-sm py-2 px-4"
            >Tout séléctionner</button>
        </div>
        <ul>
            <li v-for="station in filteredStations" :key="station.id">
                <div>
                    <input
                        @click="toggleStationEquipments(station)"
                        :checked="checkedStations.includes(station.id)"
                        type="checkbox"
                    >
                    {{station.mqttTopic}} - {{station.name}}
                </div>
                <div class="pl-5">
                    <li v-for="installation in station.equipmentInstallations" :key="installation.mqttTopic">
                        <div class="flex items-center gap-2">
                            <div>
                                <input
                                    type="checkbox"
                                    v-model="topicsStates[station.id][installation.mqttTopic].checked"
                                />
                                {{installation.mqttTopic}}
                            </div>
                            <div v-if="topicsStates[station.id][installation.mqttTopic].success" class="text-green-500">
                                <icon type="mdi" :path="icons.mdiCheckCircle"/>
                            </div>
                            <div v-else-if="topicsStates[station.id][installation.mqttTopic].error" class="text-red-500">
                                <icon type="mdi" :path="icons.mdiCloseCircle"/>
                            </div>
                            <div v-else-if="topicsStates[station.id][installation.mqttTopic].loading" class="animate-spin">
                                <icon type="mdi" :path="icons.mdiLoading"/>
                            </div>
                        </div>
                    </li>
                </div>
            </li>
        </ul>
        <div class="mt-4">
            <button 
                @click="sendCommand"
                :disabled="buttonDisabled"
                :class="{
                    'bg-gray-300 dark:bg-gray-700': buttonDisabled,
                    'bg-blue-500 hover:bg-blue-500 text-white dark:bg-gray-700 dark:hover:bg-gray-600': !buttonDisabled
                }"
                class="px-4 py-2 rounded-full transition-colors
                     dark:bg-gray-700 dark:hover:bg-gray-600"
            >Envoyer la commande</button>
        </div>
    </div>
</template>

<script>
import Dropdown from "./Dropdown.vue";
import CommandsViewLoading from "./CommandsViewLoading.vue";
import {ApiDataSource} from "../apollo/apiDataSource.js";
import * as icons from "@mdi/js";

export default  {
    components: { CommandsViewLoading, Dropdown },
    name: "CommandsView",
    mounted() {
        console.dir(this.$route)
        this.resetFields()
    },
    data() {
        return {
            icons,
            selectedCommand: null,
            selectedValue: null,
            duration: true,
            inputDurationH: "02",
            inputDurationM: "00",
            inputDurationS: "00",
            filteredStations: [],
            topicsStates: {},
            preselectAllStations: this.$route.query.preselectAllStations != "false",
            preselectedStations: (this.$route.query.preselectedStations || "").split(",")
        }
    },
    computed: {
        group() {
            return this.$store.state.group.group;
        },
        buttonDisabled() {
            return !(this.selectedCommand && this.selectedValue && this.checkedStations.length);
        },
        availableCommands() {
            return this.$store.state.availableCommands.commands.map((c) => {
                const newCommand = Object.assign({}, c);
                newCommand.text = c.title;
                return newCommand;
            });
        },
        availableValues() {
            if(!this.selectedCommand) return [];

            return this.selectedCommand.availableCommandOptions.map((o) => {
                const newOption = Object.assign({}, o);
                newOption.text = o.title;
                return newOption;
            });
        },
        checkedStations() {
            return Object.entries(this.topicsStates)
                .filter(([_, stationTopicsStates]) => {
                    return Object.values(stationTopicsStates).some(({checked}) => checked)
                })
                .map(([stationId, _]) => stationId)
        }
    },
    methods: {
        resetFields(e) {
            this.selectedCommand = e
            this.selectedValue = null;
            this.inputValue = 0;

            this.filteredStations = this.group.stations.map((s) => {
                let filteredStation = Object.assign({}, s);
                filteredStation.equipmentInstallations = (s.equipmentInstallations || []).filter((e) => {
                    return this.selectedCommand &&
                           e.equipment.equipmentIdentifier == this.selectedCommand.equipmentIdentifier
                });
                return filteredStation;
            }).filter(s => s.equipmentInstallations.length);

            this.topicsStates = Object.fromEntries(this.filteredStations.map((station) => [ 
                station.id,
                Object.fromEntries(station.equipmentInstallations.map((ei) => [ 
                    ei.mqttTopic,
                    (this.topicsStates[station.id] || {})[ei.mqttTopic] || {
                        checked: this.preselectAllStations || this.preselectedStations.includes(station.id),
                        loading: false,
                        success: false,
                        error: false
                    }
                ]))
            ]))
        },
        checkDuration() {
            if((parseInt(this.inputDurationH)*3600 + parseInt(this.inputDurationM)*60 + parseInt(this.inputDurationS)) > (2*3600)) {
                this.inputDurationH = 2;
                this.inputDurationM = 0;
                this.inputDurationS = 0;
            }
        },
        toggleStationEquipments(station) {
            Object.values(this.topicsStates[station.id]).forEach(state => {
                state.checked = !this.checkedStations.includes(station.id)
            })
        },
        selectAll() {
            const newCheckedState = this.checkedStations.length == 0
            Object.values(this.topicsStates)
                .flatMap(state => Object.values(state))
                .forEach(state => state.checked = newCheckedState)
        },
        async sendCommand() {
            if(this.buttonDisabled)
            {
                return;
            }

            const command = this.selectedCommand.command;
            const value = this.selectedValue.value;
            const duration = this.duration ? parseInt(this.inputDurationH) * 3600 +
                                             parseInt(this.inputDurationM) * 60 +
                                             parseInt(this.inputDurationS) : 0;

            await ApiDataSource.fetchAccessTokenIfExpired();
            const equipmentsToExecute = Object.entries(this.topicsStates)
                .flatMap(([stationId, equipment]) =>
                    Object.entries(equipment)
                        .filter(([_, {checked}]) => checked)
                        .map(([mqttTopic, _]) => ({
                            stationId, mqttTopic
                        }))
                )

            await Promise.all(equipmentsToExecute.map(async ({stationId, mqttTopic}) => {
                this.topicsStates[stationId][mqttTopic].loading = true
                this.topicsStates[stationId][mqttTopic].error = false
                this.topicsStates[stationId][mqttTopic].success = false
                const res = await ApiDataSource.sendCommand(
                    stationId,
                    command,
                    mqttTopic,
                    value,
                    duration
                );
                this.topicsStates[stationId][mqttTopic].loading = false
                if(res.success)
                {
                    this.topicsStates[stationId][mqttTopic].success = true
                }
                else
                {
                    this.topicsStates[stationId][mqttTopic].error = true
                }
            }))
        }
    }
}
</script>

<style scoped>
input[type="checkbox"] {
    @apply w-5 h-5 text-blue-500 rounded-sm outline-none focus:outline-none;
}

input[type="number"] {
    @apply h-8 w-12 p-1 outline-none focus:outline-none border-t-0 border-l-0 border-r-0 border-b-2 focus:border-blue-500
         border-gray-100 dark:border-gray-600 dark:text-white dark:bg-gray-800;
}

</style>

import { ApiDataSource } from "../../apollo/apiDataSource";
import loadingModule from "./loading.module.js";

export default {
    namespaced: true,
    state() {
        return {
            station: null,
            commands: [],
            events: [],
            eventsCount: 0,
            eventsPage: 0,
            eventsPageSize: 0
        }
    },
    mutations: {
        setStation(state, station) {
            state.station = station;
        },
        setCommands(state, commands) {
            state.commands = commands;
        },
        setEventsCount(state, count) {
            state.eventsCount = count;
        },
        setEventsPage(state, page) {
            state.eventsPage = page;
        },
        setEventsPageSize(state, size) {
            state.eventsPageSize = size;
        },
        setEvents(state, events) {
            state.events = events;
        }
    },
    actions: {
        async fetch(context, stationId) {
            const stationPromise = context.dispatch("fetchStation", stationId);
            const commandsPromise = context.dispatch("fetchCommands", stationId);
            const eventsPromise = context.dispatch("fetchEvents", {stationId});
            await stationPromise;
            await commandsPromise;
            await eventsPromise;
        },
        async fetchStation(context, stationId) {
            context.dispatch("loading/start");
            await ApiDataSource.fetchAccessTokenIfExpired();
            const station = await ApiDataSource.getStation(stationId);
            context.commit("setStation", station);
            context.dispatch("loading/stop");
        },
        async fetchCommands(context, stationId) {
            context.dispatch("commandsLoading/start");
            await ApiDataSource.fetchAccessTokenIfExpired();
            const commands = await ApiDataSource.getRunningCommands(stationId);
            context.commit("setCommands", commands);
            context.dispatch("commandsLoading/stop");
        },
        async fetchEvents(context, {stationId, page = 1}) {
            context.dispatch("eventsLoading/start");
            await ApiDataSource.fetchAccessTokenIfExpired();
            const {events, count, pageSize} = await ApiDataSource.getEvents(stationId, page);
            context.commit("setEvents", events);
            context.commit("setEventsCount", count);
            context.commit("setEventsPage", page);
            context.commit("setEventsPageSize", pageSize);
            context.dispatch("eventsLoading/stop");
        },
        async setEventSeen(context, {eventId, seen}) {
            context.dispatch("eventsLoading/start");
            await ApiDataSource.fetchAccessTokenIfExpired();
            await ApiDataSource.setEventSeen(eventId, seen);
            context.dispatch("eventsLoading/stop");
        }
    },
    modules: {
        loading: loadingModule,
        commandsLoading: loadingModule,
        eventsLoading: loadingModule
    }
}

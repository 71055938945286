<template>
    <div class="dark:bg-gray-800 bg-white p-6 rounded-md shadow-md">
        <div class="mb-6 flex items-center">
            <h2 class="text-2xl font-bold">Utilisateurs</h2>
            <router-link class="ml-auto flex rounded-full px-4 py-2
                bg-blue-500 hover:bg-blue-600 text-white
                dark:bg-gray-700 dark:hover:bg-gray-600" :to="{name: 'CreateUser'}">
                <icon type="mdi" :path="icons.mdiPlus"/>
            </router-link>
        </div>
        <div class="overflow-x-auto">
            <div class="users-table table table-auto w-full">
                <div class="table-row font-bold">
                    <div class="table-cell">Nom d'utilisateur</div>
                    <div class="table-cell">Email</div>
                    <div class="table-cell text-center">Administrateur</div>
                    <div class="table-cell text-center">Opérateur</div>
                    <div class="table-cell text-center">Entretien</div>
                    <div class="table-cell"></div>
                    <div class="table-cell"></div>
                </div>
                <div v-for="user in $store.state.user.users" :key="user.id" class="table-row">
                    <div class="table-cell">{{user.username}}</div>
                    <div class="table-cell">{{user.email}}</div>
                    <div class="table-cell text-center"><input disabled type="checkbox" :checked="user.isAdmin"></div>
                    <div class="table-cell text-center"><input disabled type="checkbox" :checked="user.isOperator"></div>
                    <div class="table-cell text-center"><input disabled type="checkbox" :checked="user.isMaintenance"></div>
                    <div class="table-cell w-1 px-4 align-middle">
                        <router-link :to="{name: 'UpdateUser', params: {userId: user.id}}">
                            <icon type="mdi" :path="icons.mdiPencil"/>
                        </router-link>
                    </div>
                    <div class="table-cell w-1 px-4 align-middle text-red-600">
                        <button @click="deleteUser(user.id)">
                            <icon type="mdi" :path="icons.mdiDelete"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as icons from "@mdi/js";

export default {
    name: "UsersView",
    data() {
        return {
            icons
        }
    },
    methods: {
        deleteUser(id) {
            this.$store.dispatch("user/deleteUser", id);
        }
    }
}
</script>

<style scoped>
.users-table {
    border-spacing: 8px;
}

.users-table div {
    @apply whitespace-nowrap;
}
</style>

<template>
    <div>
        <div class="dark:bg-gray-800 bg-white p-6 rounded-md shadow-md mx-auto lg:mx-0 max-w-lg">
            <h2 class="text-2xl font-bold mb-8">{{title}}</h2>
            <form @submit.prevent="submit">
                <div class="flex flex-col gap-8">
                    <div :class="{error: usernameError}">
                        <label for="username">Nom d'utilisateur</label>
                        <input v-model="username" type="text" name="username" placeholder="Arthur">
                        <div v-if="usernameError" class="error-msg">{{usernameError.message}}</div>
                    </div>
                    <div :class="{error: emailError}">
                        <label for="email">Email</label>
                        <input v-model="email" type="email" name="email" placeholder="example@test.com">
                        <div v-if="emailError" class="error-msg">{{emailError.message}}</div>
                    </div>
                    <div :class="{error: passwordError}">
                        <label for="new-password">Mot de passe</label>
                        <input v-model="newPassword" type="password" name="new-password" placeholder="secret">
                        <div v-if="passwordError" class="error-msg">{{passwordError.message}}</div>
                    </div>
                    <div :class="{error: passwordMatchError}">
                        <label for="new-password-repeat">Répéter le mot de passe</label>
                        <input v-model="newPasswordRepeat" type="password" name="new-password-repeat" placeholder="secret">
                        <div v-if="passwordMatchError" class="error-msg">Les mots de passes ne correspondent pas</div>
                    </div>
                    <div v-if="$route.name != 'Account'" class="flex items-top gap-2 justify-around text-center font-bold">
                        <div class="flex-1">
                            <label for="admin">Administrateur</label>
                            <div><input type="checkbox" name="admin" v-model="isAdmin"></div>
                        </div>
                        <div class="flex-1">
                            <label for="operator">Opérateur</label>
                            <div><input type="checkbox" name="operator" v-model="isOperator"></div>
                        </div>
                        <div class="flex-1">
                            <label for="maintenance">Maintenance</label>
                            <div><input type="checkbox" name="maintenance" v-model="isMaintenance"></div>
                        </div>
                    </div>
                </div>
                <div class="mt-8">
                    <input v-if="!loading && !submitDisabled" type="submit" value="Sauvergarder" class="mx-auto">
                    <div v-if="loading" class="w-max mx-auto animate-spin">
                        <icon type="mdi" :path="icons.mdiLoading" :size="32"/>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import * as icons from "@mdi/js";
export default {
    name: "AccountView",
    data() {
        const user = this.defaultUserValues();
        return {
            icons,
            loading: false,
            validationErrors: [],
            username: user.username,
            email: user.email,
            newPassword: "",
            newPasswordRepeat: "",
            isAdmin: user.isAdmin,
            isOperator: user.isOperator,
            isMaintenance: user.isMaintenance
        }
    },
    methods: {
        defaultUserValues() {
            if(this.$route.name == "Account") return this.$store.state.loggedUser.user;
            else if(this.$route.name == "UpdateUser") return this.$store.state.user.user;
            else return {
                username: "",
                email: "",
                isAdmin: false,
                isOperator: false,
                isMaintenance: false
            }
        },
        async submit() {
            this.loading = true;
            this.validationErrors = [];
            const userToSend = {
                username: this.username,
                email: this.email,
                password: this.newPassword || undefined,
                isAdmin: this.isAdmin,
                isOperator: this.isOperator,
                isMaintenance: this.isMaintenance
            };

            try 
            {
                if(this.$route.name == "Account")
                {
                    userToSend.id = this.$store.state.loggedUser.user.id;
                    await this.$store.dispatch("user/updateUser", userToSend);
                }
                else if (this.$route.name == "UpdateUser")
                {
                    userToSend.id = this.$store.state.user.user.id;
                    await this.$store.dispatch("user/updateUser", userToSend);
                }
                else if(this.$route.name == "CreateUser")
                {
                    await this.$store.dispatch("user/createUser", userToSend);
                    this.$router.push("/users");
                }
            }
            catch(err)
            {
                for(const error of err.graphQLErrors)
                {
                    if(error.extensions.code == "VALIDATION_ERROR")
                    {
                        this.validationErrors = error.extensions.validationErrors;
                        console.log(this.validationErrors);
                    }
                }
            }
            this.loading = false;
        }
    },
    computed: {
        title() {
            if(this.$route.name == "Account") return "Compte";
            else if(this.$route.name == "UpdateUser") return "Editer utilisateur";
            else if(this.$route.name == "CreateUser") return "Nouvel utilisateur";
        },
        passwordMatchError() {
            return this.newPassword && this.newPasswordRepeat && this.newPassword != this.newPasswordRepeat;
        },
        submitDisabled() {
            return ((this.newPassword || this.newPasswordRepeat) && this.newPassword != this.newPasswordRepeat) || (this.$route.name == "CreateUser" && !this.newPassword);
        },
        usernameError() {
            return this.validationErrors.filter(e => e.field == "username").pop();
        },
        emailError() {
            return this.validationErrors.filter(e => e.field == "email").pop();
        },
        passwordError() {
            return this.validationErrors.filter(e => e.field == "password").pop();
        }
    }
}
</script>

<style scoped>
input[type="checkbox"] {
    @apply rounded-full w-6 h-6;
}
</style>
